var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "title" }, [_vm._v("门头照")]),
      _c(
        "div",
        { staticClass: "img-wrap" },
        _vm._l(_vm.doorPhotoRespVos, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "img-content" },
            [
              _c("el-image", {
                staticStyle: {
                  width: "100px",
                  height: "100px",
                  "margin-right": "20px",
                },
                attrs: { src: item, "preview-src-list": _vm.doorPhotoRespVos },
              }),
              _c("span", [
                _vm._v(
                  "判断结果：" + _vm._s(_vm.isStandard(item) ? "异常" : "合格")
                ),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "title" }, [_vm._v("陈列照")]),
      _c(
        "div",
        { staticClass: "img-wrap" },
        _vm._l(_vm.displayPhotoRespVos, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "img-content" },
            [
              _c("el-image", {
                staticStyle: {
                  width: "100px",
                  height: "100px",
                  "margin-right": "20px",
                },
                attrs: {
                  src: item,
                  "preview-src-list": _vm.displayPhotoRespVos,
                },
              }),
              _c("span", [
                _vm._v(
                  "判断结果：" + _vm._s(_vm.isStandard(item) ? "异常" : "合格")
                ),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _vm._l(_vm.pickupsPhotoRespVos, function (item, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("第" + _vm._s(index + 1) + "次补拍照"),
          ]),
          _c(
            "div",
            { staticClass: "img-wrap" },
            _vm._l(item.photos, function (iItem, iIndex) {
              return _c(
                "div",
                { key: iIndex, staticClass: "img-content" },
                [
                  _c("el-image", {
                    staticStyle: {
                      width: "100px",
                      height: "100px",
                      "margin-right": "20px",
                    },
                    attrs: { src: iItem, "preview-src-list": item.photos },
                  }),
                  _c("span", [
                    _vm._v(
                      "判断结果：" +
                        _vm._s(_vm.isStandard(iItem) ? "异常" : "合格")
                    ),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }