import Form, { formCreate } from '@/found/components/form';

import request from '@/found/utils/request';
import ProductRespVos from './product_info.vue';
import PhotoRespVos from './photo_list.vue';

formCreate.component('ProductRespVos', ProductRespVos);
formCreate.component('PhotoRespVos', PhotoRespVos);

export default {
  name: 'table-component',
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'display_fees_report_form',
    };
  },
  methods: {
    formComplete() {
      if (this.formConfig.code === 'view') {
        // this.setValue(this.formConfig.row);
        this.setValue({ ...this.formConfig.row, photoRespVos: this.formConfig.row });
        const tempData = this.formConfig.row;
        if (tempData && ((tempData.doorPhotoRespVos && tempData.doorPhotoRespVos.length !== 0) || (tempData.displayPhotoRespVos && tempData.displayPhotoRespVos.length !== 0) || (tempData.pickupsPhotoRespVos && tempData.pickupsPhotoRespVos.length !== 0))) {
          this.hiddenFields(false, ['photoRespVos', 'title2']);
        } else {
          this.hiddenFields(true, ['photoRespVos', 'title2']);
        }
        if (tempData && tempData.productRespVos && tempData.productRespVos.length !== 0) {
          this.hiddenFields(false, ['productRespVos', 'title1']);
        } else {
          this.hiddenFields(true, ['productRespVos', 'title1']);
        }
      }
    },
    // 校验图片
    checkPhoto(pics) {
      request.post('/sfa/sensegalaxyfilestatus/queryFileStatus', pics).then((res) => {
        console.log(res);
        if (res.success) {
          this.getRule('photoRespVos').props.checkPicList = res.result || [];
        }
      });
    },
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'productRespVos') {
        v.props.value = this.formConfig.row.productRespVos || [];
      }
      if (v.field === 'photoRespVos') {
        v.props.value = this.formConfig.row || {};
        v.props.checkPicList = [];
        const photoList = this.formConfig.row.photoRespVos || [];
        this.checkPhoto(photoList.map((pics) => pics.photo));
      }
      return v;
    },
  },

};
