<template>
  <div>
    <div class="title">门头照</div>
    <div class="img-wrap">
      <div class="img-content" v-for="(item, index) in doorPhotoRespVos" :key="index">
        <el-image
          style="width: 100px; height: 100px; margin-right: 20px"
          :src="item"
          :preview-src-list="doorPhotoRespVos"
        >
        </el-image>
        <span>判断结果：{{isStandard(item)? '异常': '合格'}}</span>
      </div>
    </div>
    <div class="title">陈列照</div>
    <div class="img-wrap">
      <div class="img-content" v-for="(item, index) in displayPhotoRespVos" :key="index">
        <el-image
          style="width: 100px; height: 100px; margin-right: 20px"
          :src="item"
          :preview-src-list="displayPhotoRespVos"
        >
        </el-image>
        <span>判断结果：{{isStandard(item)? '异常': '合格'}}</span>
      </div>
    </div>
    <div v-for="(item, index) in pickupsPhotoRespVos" :key="index">
      <div class="title">第{{index+1}}次补拍照</div>
      <div class="img-wrap">
        <div class="img-content" v-for="(iItem, iIndex) in item.photos" :key="iIndex">
          <el-image
            style="width: 100px; height: 100px; margin-right: 20px"
            :src="iItem"
            :preview-src-list="item.photos"
          >
          </el-image>
          <span>判断结果：{{isStandard(iItem)? '异常': '合格'}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    checkPicList: Array,
  },
  watch: {
    value() {
      console.log(this.value);
      // const temp = [];
      // for (const item of this.value) {
      //   temp.push(item.photo);
      // }
      // this.imgList = temp;
      // 门头照
      const temp = [];
      if (
        this.value.doorPhotoRespVos
        && this.value.doorPhotoRespVos.length > 0
      ) {
        for (const item of this.value.doorPhotoRespVos) {
          temp.push(item.photo);
        }
      }
      this.doorPhotoRespVos = temp;
      // 陈列照
      const temp1 = [];
      if (
        this.value.displayPhotoRespVos
        && this.value.displayPhotoRespVos.length > 0
      ) {
        for (const item of this.value.displayPhotoRespVos) {
          temp1.push(item.photo);
        }
      }
      this.displayPhotoRespVos = temp1;
      // 补拍照
      const temp2 = [];
      if (
        this.value.pickupsPhotoRespVos
        && this.value.pickupsPhotoRespVos.length > 0
      ) {
        for (const item of this.value.pickupsPhotoRespVos) {
          temp2.push(item);
        }
      }
      this.pickupsPhotoRespVos = temp2;
      console.log(this.doorPhotoRespVos, this.displayPhotoRespVos, this.pickupsPhotoRespVos);
    },
    checkPicList(val) {
      console.log(val);
      this.checkImageList = val;
    },
  },
  computed: {
    isStandard() {
      return (pic) => this.checkImageList.some((p) => p.fileUrl === pic);
    },
  },
  data() {
    return {
      doorPhotoRespVos: [],
      displayPhotoRespVos: [],
      pickupsPhotoRespVos: [],
      checkImageList: [],
      imgList: [],
    };
  },

  methods: {},
};
</script>
<style scoped>
.title {
  font-size: 16px;
  padding: 10px 0;
}
.img-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.img-content{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>
